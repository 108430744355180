/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Toaster } from "react-hot-toast";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Base from "./components/Layouts/Base";
import Raids from "./pages/Raids";

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
            <Route path='/raids' element={<Raids />} />
            {/* <Route path="/*" element={<PageNotFound />}/> */}
          </Routes>
        </Base>
      </BrowserRouter>
      <Toaster reverseOrder={false} position='top-center' />
    </div>
  );
}

export default App;
