/** @format */

import React from "react";

const Roadmap = () => {
  return (
    <div name='roadmap' className=' pt-12 relative'>
      <h5 className='text-center font-sans text-[#000] lg:text-7xl text-4xl'>
        ROADMAP
      </h5>
      <div className='absolute    bottom-0 right-2'>
        <img src='assets/images/push.png' className='w-1/4' alt='' />
      </div>
      <div class='tree-wrapper relative md:w-[10px] min-h-[50vh] md:mx-auto '>
        <div class='flex md:flex-row flex-col md:items-center md:gap-2 group  relative top-0 md:left-1/2 md:right-1/2 md:even:translate-x-[-100%] even:translate-x-0 md:w-max w-full z-[1]'>
          <div class='bg-white px-5 py-4 font-sans  text-3xl  border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px] pointer p-4   aspect-square w-[80px] flex items-center justify-center rounded-full md:group-even:order-2 group-even:order-none md:group-odd:-translate-x-1/2 md:translate-x-1/2 translate-x-0 mx-auto md:mx-0'>
            PHASE 01
          </div>
          <div class='details md:max-w-sm p-5 bg-white px-5 py-4 rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px] font-bold my-3'>
            <ul className=' list-disc tree'>
              <li>Fairlaunch</li>
              <li>Listing on raydium</li>
              <li>CoinGecko Listing</li>
              <li>Coinmarketcap Listings</li>
              <li>1000+ Holders</li>
              <li>Turned out to be the greatest meme coin ever</li>
            </ul>
          </div>
        </div>
        <div class='flex md:flex-row flex-col md:items-center md:gap-2 group  relative top-0 md:left-1/2 md:right-1/2 md:even:translate-x-[-100%] even:translate-x-0 md:w-max w-full z-[1]'>
          <div class='bg-white px-5 py-4 font-sans  text-3xl  border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px] pointer p-4   aspect-square w-[80px] flex items-center justify-center rounded-full md:group-even:order-2 group-even:order-none md:group-odd:-translate-x-1/2 md:translate-x-1/2 translate-x-0 mx-auto md:mx-0'>
            PHASE 02
          </div>
          <div class='details md:max-w-sm p-5 bg-white px-5 py-4 rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px] font-bold my-3'>
            <ul className='list-disc'>
              <li>Partnerships</li>
              <li>
                Aggressive Marketing ($Mouski word of mouth for everyone )
              </li>
              <li>Exclusive holder content/community hang outs</li>
              <li>2500+ holders</li>
              <li>CEX Listings</li>
            </ul>
          </div>
        </div>
        <div class='flex md:flex-row flex-col md:items-center md:gap-2 group  relative top-0 md:left-1/2 md:right-1/2 md:even:translate-x-[-100%] even:translate-x-0 md:w-max w-full z-[1]'>
          <div class='bg-white px-5 py-4 font-sans  text-3xl  border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px] pointer p-4   aspect-square w-[80px] flex items-center justify-center rounded-full md:group-even:order-2 group-even:order-none md:group-odd:-translate-x-1/2 md:translate-x-1/2 translate-x-0 mx-auto md:mx-0'>
            PHASE 03
          </div>
          <div class='details md:max-w-sm p-5 bg-white px-5 py-4 rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px] font-bold my-3'>
            <ul className='list-disc'>
              <li>Utility partnerships</li>
              <li>Multichain bridging</li>
              <li>T1 Exchange Listings</li>
              <li>10,000+ holders</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='mt-5'>
        <div className='bg-[#9945FF]  py-[20px]'></div>
        <div className='bg-[#14F195]  py-[10px]'></div>
        <div className='bg-[#9f64e7]  py-[10px]'></div>
      </div>
    </div>
  );
};

export default Roadmap;
