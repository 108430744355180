/** @format */

import React, { useState } from "react";
import DocumentMeta from "react-document-meta";

const Raids = () => {
  const [images, setImages] = useState([
    "assets/meme/2.jpg",
    "assets/meme/3.jpg",
    "assets/meme/4.jpg",
    "assets/meme/1.jpg",
    "assets/meme/5.jpg",
    "assets/meme/6.jpg",
    "assets/meme/7.jpg",
  ]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const shuffleImages = () => {
    const newIndex = Math.floor(Math.random() * images.length);
    setCurrentImageIndex(newIndex);
  };

  //   React.useEffect(() => {
  //     document.title = " Raid Generator - Mouski";
  //   }, []);

  const imageUri = encodeURI(
    `https://mouski.xyz/assets/meme/${currentImageIndex + 1}.jpg`,
  );
  const meta = {
    title: "Raid Generator - Mouski",
    meta: {
      property: {
        "og:title": "Raid Generator - Mouski",
        "og:url": "https://mouski.xyz/raids/",
        "og:image": imageUri,
        "og:description": "Raid Generator",

        "twitter:card": "summary_large_image",
        "twitter:title": "Raid Generator - Mouski",
        "twitter:description": "Raid Generator",
        "twitter:image": imageUri,
      },
    },
  };
  console.log(imageUri);
  const handleRaidClick = () => {
    const memeID = currentImageIndex + 1;
    const url = `https://mouski.xyz/raids?memeID=${memeID}`;

    const tweetUrl = `https://twitter.com/intent/tweet?url=${url}&text=Check%20out%20this%20cool%20meme!`;

    window.open(tweetUrl, "_blank");
  };

  return (
    <div name='section2' className='pt-32'>
      <DocumentMeta {...meta} />
      <div className='w-full'>
        <div className='container-md'>
          <h4 className='text-center font-sans text-[#000] text-7xl'>Raids</h4>
          <p className='text-[#000] text-center py-1 lg:text-lg text-lg font-sans'>
            Weeeee raiiddd Faaaasssttt
          </p>
          <img
            src={images[currentImageIndex]}
            alt={` ${currentImageIndex + 1}`}
            className='max-w-lg w-full m-auto rounded-lg '
          />
          <div className='flex justify-center gap-4 mt-6'>
            <button
              onClick={shuffleImages}
              className='cursor-pointer flex justify-between items-center text-xl font-sans bg-gray-800 px-3 py-2 rounded-full text-white tracking-wider shadow-xl hover:bg-gray-900 hover:scale-105 duration-500 hover:ring-1 w-[150px]'>
              Shuffle
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                className='bi bi-shuffle w-5 h-5 animate-bounce'
                viewBox='0 0 16 16'>
                <path
                  fillRule='evenodd'
                  d='M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.6 9.6 0 0 0 7.556 8a9.6 9.6 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.6 10.6 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.6 9.6 0 0 0 6.444 8a9.6 9.6 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5'
                />
                <path d='M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192' />
              </svg>
            </button>
            <button
              onClick={() => handleRaidClick(images[currentImageIndex])}
              className="cursor-pointer font-sans uppercase text-white font-bold relative text-[22px] w-[7em] text-center bg-gradient-to-r from-violet-500 from-10% via-sky-500 via-30% to-pink-500 to-90% bg-[length:400%] rounded-[30px] z-10 hover:animate-gradient-xy hover:bg-[length:100%] before:content-[''] before:absolute before:-top-[2px] before:-bottom-[2px] before:-left-[2px] before:-right-[5px] before:bg-gradient-to-r before:from-violet-800 before:from-10% before:via-sky-500 before:via-30% before:to-pink-500 before:bg-[length:400%] before:-z-10 before:rounded-[35px] before:hover:blur-xl before:transition-all before:ease-in-out before:duration-[1s] before:hover:bg-[length:10%] active:bg-violet-700 focus:ring-violet-700">
              Raid
            </button>
          </div>
        </div>
        <div className='mt-5'>
          <div className='bg-[#000] py-[2px]'></div>
          <div className='bg-[#9945FF] py-[20px]'></div>
          <div className='bg-[#14F195] py-[10px]'></div>
          <div className='bg-[#9f64e7] py-[10px]'></div>
        </div>
      </div>
    </div>
  );
};

export default Raids;
