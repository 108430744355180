/** @format */

import React from "react";
import Hero from "../components/Elements/Hero";
import Sections from "../components/Elements/Sections";
import Mouski from "../components/Elements/Mouski";
import Roadmap from "../components/Elements/Roadmap";

const Home = () => {
  return (
    <>
      <Hero />
      <Sections />
      <Mouski />
      <Roadmap />
    </>
  );
};

export default Home;
