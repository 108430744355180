/** @format */

import React from "react";

const Sections = () => {
  return (
    <>
      <div name='section2' className=' pt-8'>
        <div className='container-md '>
          <div className='grid lg:grid-cols-2 gap-8'>
            <div>
              <div className='bg-white px-4 py-4 rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px]  relative z-10'>
                <h4 className='text-center font-sans text-[#000] text-6xl'>
                  WHO IS Mouski
                </h4>
                <p className='text-center font-bold text-[#000]'>
                  Mouski, one of the deadliest mosquitoes in the world, is
                  mainly found in the Amazon rainforest. Its mission is to
                  conquer the meme world by biting and defeating all other meme
                  coins, making them obsolete. It's Mouski's turn to soar to the
                  moon, as trends like{" "}
                  <strong className=' text-red-600 text-lg'>Doge</strong>,{" "}
                  <strong className=' text-red-600 text-lg'> Pepe</strong>,{" "}
                  <strong className=' text-red-600 text-lg'> Shiba</strong>,
                  <strong className=' text-red-600 text-lg'> WIF</strong>,
                  <strong className=' text-red-600 text-lg'> SLERF</strong> and
                  <strong className=' text-red-600 text-lg'> PENG </strong>
                  are fading away, paving the way for Mouski to reach new
                  heights in the meme world.
                </p>
              </div>
            </div>
            <div>
              <img src='assets/images/bath.png' className='-mt-8 relative z-10' alt='' />
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <div className='bg-[#000]  py-[2px]'></div>
          <div className='bg-[#9945FF]  py-[20px]'></div>
          <div className='bg-[#14F195]  py-[10px]'></div>
          <div className='bg-[#9f64e7]  py-[10px]'></div>
        </div>
      </div>
    </>
  );
};

export default Sections;
