/** @format */

import React from "react";

const Mouski = () => {
  return (
    <>
      <div name='Mouski' className=' pt-12 relative'>
        <h5 className='text-center font-sans text-[#000] lg:text-7xl text-4xl'>
          MOUSKINOMICS
        </h5>
        <div className='absolute top-0'>
          <img src='assets/images/hanging.png' className='w-1/4' alt='' />
        </div>
        <div className='flex justify-center gap-8 px-2 items-center flex-wrap'>
          <div className='bg-white px-5 py-4 rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px] relative z-10'>
            <div className='row m-auto'>
              <div className='col-md-6 mt-3'>
                <h4 className='text-white bg-gra py-2 px-3 lg:my-4 font-extrabold uppercase text-xl text-center rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px]'>
                  Current Supply 6,437,999.05
                </h4>
                <h4 className='text-white bg-gra py-2 px-3 lg:my-4 font-extrabold text-xl text-center rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px]'>
                  51% LP <br /> PRESALE
                </h4>
              </div>

              <div className='col-md-6 mt-3'>
                <h4 className='text-white bg-gra py-2 px-3 lg:my-4 font-extrabold text-xl text-center rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px]'>
                  LP LOCK <br /> AT PINKSALE
                </h4>
                <h4 className='text-white bg-gra py-2 px-3 lg:my-4 font-extrabold text-xl text-center rounded-2xl border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px]'>
                  OWNERSHIP <br /> REVOKED
                </h4>
              </div>
            </div>
          </div>
          <div>
            <div className='bg animate relative z-10'>
              <div className='stage'>
                <div className='logo'>
                  <svg
                    version='1.1'
                    id='Layer_1'
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    x='0px'
                    y='0px'
                    viewBox='0 0 1259.1 1027.2'
                    style={{ enableBackground: "new 0 0 1259.1 1027.2" }}
                    xmlSpace='preserve'>
                    <linearGradient
                      id='SVGID_1_'
                      gradientUnits='userSpaceOnUse'
                      x1='265.0926'
                      y1='538.3393'
                      x2='994.1592'
                      y2='1267.4124'
                      gradientTransform='matrix(1 0 0 -1 0 1030)'>
                      <stop offset={0} style={{ stopColor: "#888BCF" }} />
                      <stop offset={1} style={{ stopColor: "#10F4B1" }} />
                    </linearGradient>
                    <path
                      className='st0'
                      d='M13.9,174.1L173,13.9C181.8,5,193.8,0,206.3,0H1212c41.7,0,62.8,50.4,33.3,80l-158.9,160.2
	c-8.8,8.9-20.7,13.9-33.2,13.9H47.1C5.4,254.1-15.7,203.7,13.9,174.1z'
                    />
                    <linearGradient
                      id='SVGID_2_'
                      gradientUnits='userSpaceOnUse'
                      x1='-3188.6152'
                      y1='801.3309'
                      x2='-2618.7148'
                      y2='231.4303'
                      gradientTransform='matrix(-1 0 0 -1 -2274.145 1030)'>
                      <stop offset={0} style={{ stopColor: "#6EC7BE" }} />
                      <stop offset={1} style={{ stopColor: "#957CD4" }} />
                    </linearGradient>
                    <path
                      className='st1'
                      d='M1211.9,640.7H206c-12.5-0.1-24.4-5.1-33.2-13.9L13.9,466.5c-29.6-29.6-8.5-80,33.3-80h1005.7
	c12.5,0,24.5,5,33.3,13.9l159.1,160.2C1274.8,590.2,1253.7,640.7,1211.9,640.7z'
                    />
                    <linearGradient
                      id='SVGID_3_'
                      gradientUnits='userSpaceOnUse'
                      x1='-3268.1621'
                      y1='-1230.4384'
                      x2='-2539.1108'
                      y2='-501.3807'
                      gradientTransform='matrix(-1 0 0 1 -2274.145 1766.0181)'>
                      <stop offset={0} style={{ stopColor: "#70B7C2" }} />
                      <stop offset='0.1607' style={{ stopColor: "#77AFC5" }} />
                      <stop offset='0.4113' style={{ stopColor: "#8A99CD" }} />
                      <stop offset='0.7184' style={{ stopColor: "#A975DA" }} />
                      <stop offset={1} style={{ stopColor: "#CB4EE8" }} />
                    </linearGradient>
                    <path
                      className='st2'
                      d='M1245.2,853.1l-159.1,160.2c-8.8,8.9-20.8,13.9-33.3,13.9H47.1c-41.7,0-62.8-50.4-33.3-80l159-160.2
	c8.8-8.9,20.7-13.9,33.2-13.9h1005.9C1253.7,773.1,1274.8,823.5,1245.2,853.1z'
                    />
                  </svg>
                </div>
                <div className='text'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 1533 197.73'>
                    <defs></defs>
                    <g id='Layer_2' data-name='Layer 2'>
                      <g id='Layer_1-2' data-name='Layer 1'>
                        <path
                          className='cls-2'
                          d='M529.3,6.44A6.44,6.44,0,0,1,535.74,0H560.4a6.44,6.44,0,0,1,6.44,6.44v152.1a6.43,6.43,0,0,0,6.44,6.43H718.94a6.44,6.44,0,0,1,4.69,10.84L705,195.7a6.48,6.48,0,0,1-4.7,2H535.74a6.44,6.44,0,0,1-6.44-6.44Z'
                        />
                        <path
                          className='cls-2'
                          d='M142.23,118H60.72s0,0,0,0h0l-1.59,0a59,59,0,1,1,0-118L60.7,0H184.81a6.44,6.44,0,0,1,4.36,11.17l-27.56,25.4a6.47,6.47,0,0,1-4.36,1.7H59.07a20.73,20.73,0,0,0,0,41.46h87.81a.09.09,0,0,1,.1.1.1.1,0,0,0,.09.1,59,59,0,0,1-4.88,117.8c-1.58,0-3.15-.06-4.7-.18-.05,0-.09,0-.09.09a.1.1,0,0,1-.1.09H14.23a6.44,6.44,0,0,1-4.18-11.33L39.73,161a6.44,6.44,0,0,1,4.19-1.55h98.31a20.73,20.73,0,1,0,0-41.46Z'
                        />
                        <path
                          className='cls-2'
                          d='M298.24,0s0,0,0,0l0,0a36.71,36.71,0,0,0-35.1,35h0a0,0,0,0,0,0,0V159.44a0,0,0,0,0,0,0s0,0,0,0c0,.52,0,1.05,0,1.58a36.72,36.72,0,0,0,35.13,36.64s0,0,0,0,0,0,0,0H432.41l0,0s0,0,0,0a36.71,36.71,0,0,0,35.13-36.64c0-.53,0-1.06,0-1.58l0,0s0,0,0,0V35.1s0,0,0,0h0a36.7,36.7,0,0,0-35.1-35V0l0,0ZM323.4,35.08h0a23,23,0,0,0-21.93,22h0v78.22h0s0,0,0,0c0,.33,0,.66,0,1a23,23,0,0,0,22,23.05h0a0,0,0,0,0,0,0h83.85s0,0,0,0h0a23,23,0,0,0,22-23.05c0-.33,0-.66,0-1a0,0,0,0,1,0,0h0V57.16h0a23,23,0,0,0-21.93-22h0a0,0,0,0,0,0,0Z'
                        />
                        <path
                          className='cls-2'
                          d='M833.43,54.22V86.05a6.45,6.45,0,0,0,6.44,6.44H954.8a6.44,6.44,0,0,0,6.44-6.44V54.22a22.35,22.35,0,0,0-22.36-22.33H855.8A22.35,22.35,0,0,0,833.43,54.22ZM967.68,197.73a6.44,6.44,0,0,1-6.44-6.44V127.63a6.44,6.44,0,0,0-6.44-6.44H839.87a6.44,6.44,0,0,0-6.44,6.44v63.66a6.44,6.44,0,0,1-6.44,6.44H801.53a6.44,6.44,0,0,1-6.44-6.44v-153l0,0s0,0,0,0c0-.52,0-1,0-1.57A36.71,36.71,0,0,1,831.83,0l1.59,0h0l0,0h131l0,0V0a36.7,36.7,0,0,1,35.13,36.64c0,.52,0,1,0,1.57a0,0,0,0,0,0,0s0,0,0,0v153a6.43,6.43,0,0,1-6.43,6.44Z'
                        />
                        <path
                          className='cls-2'
                          d='M1366.85,54.22V86.05a6.44,6.44,0,0,0,6.44,6.44h114.93a6.44,6.44,0,0,0,6.44-6.44V54.22a22.35,22.35,0,0,0-22.37-22.33h-83.07A22.35,22.35,0,0,0,1366.85,54.22ZM1501.1,197.73a6.44,6.44,0,0,1-6.44-6.44V127.63a6.44,6.44,0,0,0-6.44-6.44H1373.29a6.44,6.44,0,0,0-6.44,6.44v63.66a6.44,6.44,0,0,1-6.44,6.44h-25.47a6.43,6.43,0,0,1-6.43-6.44v-153s0,0,0,0l0,0c0-.52,0-1,0-1.57A36.71,36.71,0,0,1,1365.25,0c.53,0,1.06,0,1.58,0h0l0,0h131V0l0,0A36.7,36.7,0,0,1,1533,36.68c0,.52,0,1,0,1.57,0,0,0,0,0,0l0,0v153a6.44,6.44,0,0,1-6.44,6.44Z'
                        />
                        <path
                          className='cls-2'
                          d='M1102.07,2.26a6.46,6.46,0,0,0-4.9-2.26h-28.93a6.44,6.44,0,0,0-6.44,6.44V191.29a6.44,6.44,0,0,0,6.44,6.44h25.46a6.44,6.44,0,0,0,6.44-6.44V68.19A6.44,6.44,0,0,1,1111.43,64l116.46,133.38a.25.25,0,0,1,.06.16.24.24,0,0,0,.24.24h31.66a6.44,6.44,0,0,0,6.44-6.44V6.44A6.44,6.44,0,0,0,1259.85,0h-25.46A6.44,6.44,0,0,0,1228,6.44v126a6.44,6.44,0,0,1-11.34,4.18Z'
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <div className='bg-[#9945FF]  py-[20px]'></div>
          <div className='bg-[#14F195]  py-[10px]'></div>
          <div className='bg-[#9f64e7]  py-[10px]'></div>
        </div>
      </div>
    </>
  );
};

export default Mouski;
