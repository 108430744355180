/** @format */

import React, { useCallback, useState } from "react";
import { loadSlim } from "tsparticles-slim";
import Particles from "react-tsparticles";
import toast from "react-hot-toast";
import CountDown from "../Utils/CountDown";

const Hero = () => {
  const particlesInit = useCallback(async engine => {
    // console.log(engine);

    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    // await console.log(container);
  }, []);
  return (
    <>
      <Particles
        id='tsparticles'
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          autoPlay: true,
          background: {
            color: {
              value: "transparent",
              opacity: 0.5,
            },
            image: "",
            position: "50% 50%",
            repeat: "no-repeat",
            size: "cover",
            opacity: 0.5,
          },
          backgroundMask: {
            composite: "destination-out",
            cover: {
              color: {
                value: "#fff",
              },
              opacity: 0.5,
            },
            enable: false,
          },
          defaultThemes: {},
          delay: 0,
          fullScreen: {
            enable: true,
            zIndex: 1,
          },
          detectRetina: true,
          duration: 0,
          fpsLimit: 120,
          interactivity: {
            detectsOn: "window",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onDiv: {
                selectors: "#repulse-div",
                enable: false,
                mode: "repulse",
                type: "circle",
              },
              onHover: {
                enable: true,
                mode: "bubble",
                parallax: {
                  enable: false,
                  force: 2,
                  smooth: 10,
                },
              },
              resize: {
                delay: 0.5,
                enable: true,
              },
            },
            modes: {
              trail: {
                delay: 1,
                pauseOnStop: false,
                quantity: 1,
              },
              attract: {
                distance: 200,
                duration: 0.4,
                easing: "ease-out-quad",
                factor: 1,
                maxSpeed: 50,
                speed: 1,
              },
              bounce: {
                distance: 200,
              },
              bubble: {
                distance: 400,
                duration: 2,
                mix: false,
                opacity: 0.5,
                size: 30,
                divs: {
                  distance: 200,
                  duration: 0.4,
                  mix: false,
                  selectors: [],
                },
              },
              connect: {
                distance: 80,
                links: {
                  opacity: 0.5,
                },
                radius: 60,
              },
              grab: {
                distance: 400,
                links: {
                  blink: false,
                  consent: false,
                  opacity: 1,
                },
              },
              push: {
                default: true,
                groups: [],
                quantity: 4,
              },
              remove: {
                quantity: 2,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
                factor: 100,
                speed: 1,
                maxSpeed: 50,
                easing: "ease-out-quad",
                divs: {
                  distance: 200,
                  duration: 0.4,
                  factor: 100,
                  speed: 1,
                  maxSpeed: 50,
                  easing: "ease-out-quad",
                  selectors: [],
                },
              },
              slow: {
                factor: 3,
                radius: 200,
              },
              light: {
                area: {
                  gradient: {
                    start: {
                      value: "#ffffff",
                    },
                    stop: {
                      value: "#000000",
                    },
                  },
                  radius: 1000,
                },
                shadow: {
                  color: {
                    value: "#000000",
                  },
                  length: 2000,
                },
              },
            },
          },
          manualParticles: [],
          particles: {
            bounce: {
              horizontal: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
              vertical: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
            },
            collisions: {
              absorb: {
                speed: 2,
              },
              bounce: {
                horizontal: {
                  random: {
                    enable: false,
                    minimumValue: 0.1,
                  },
                  value: 1,
                },
                vertical: {
                  random: {
                    enable: false,
                    minimumValue: 0.1,
                  },
                  value: 1,
                },
              },
              enable: false,
              maxSpeed: 50,
              mode: "bounce",
              overlap: {
                enable: true,
                retries: 0,
              },
            },
            color: {
              value: "#ffffff",
              animation: {
                h: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  delay: 0,
                  decay: 0,
                  sync: true,
                },
                s: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  delay: 0,
                  decay: 0,
                  sync: true,
                },
                l: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  delay: 0,
                  decay: 0,
                  sync: true,
                },
              },
            },
            groups: {},
            move: {
              angle: {
                offset: 0,
                value: 90,
              },
              attract: {
                distance: 200,
                enable: false,
                rotate: {
                  x: 600,
                  y: 1200,
                },
              },
              center: {
                x: 50,
                y: 50,
                mode: "percent",
                radius: 0,
              },
              decay: 0,
              distance: {},
              direction: "none",
              drift: 0,
              enable: true,
              gravity: {
                acceleration: 9.81,
                enable: false,
                inverse: false,
                maxSpeed: 50,
              },
              path: {
                clamp: true,
                delay: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: 0,
                },
                enable: false,
                options: {},
              },
              outModes: {
                default: "out",
                bottom: "out",
                left: "out",
                right: "out",
                top: "out",
              },
              random: false,
              size: false,
              speed: 2,
              spin: {
                acceleration: 0,
                enable: false,
              },
              straight: false,
              trail: {
                enable: false,
                length: 10,
                fill: {},
              },
              vibrate: false,
              warp: false,
            },
            number: {
              density: {
                enable: true,
                width: 1920,
                height: 1080,
              },
              limit: 0,
              value: 10,
            },
            opacity: {
              random: {
                enable: true,
                minimumValue: 0.1,
              },
              value: {
                min: 0.1,
                max: 1,
              },
              animation: {
                count: 0,
                enable: true,
                speed: 1,
                decay: 0,
                delay: 0,
                sync: false,
                mode: "auto",
                startValue: "random",
                destroy: "none",
                minimumValue: 0.2,
              },
            },
            reduceDuplicates: false,
            shadow: {
              blur: 0,
              color: {
                value: "#000",
              },
              enable: false,
              offset: {
                x: 0,
                y: 0,
              },
            },
            shape: {
              close: true,
              fill: true,
              options: {
                character: {
                  fill: false,
                  font: "Verdana",
                  style: "",
                  value: "*",
                  weight: "400",
                },
                char: {
                  fill: false,
                  font: "Verdana",
                  style: "",
                  value: "*",
                  weight: "400",
                },
                polygon: {
                  sides: 5,
                },
                star: {
                  sides: 5,
                },
                image: [
                  {
                    src: "assets/images/hanging.png",
                    width: 50,
                    height: 50,
                  },
                  {
                    src: "assets/images/hanging.png",
                    width: 50,
                    height: 50,
                  },
                ],
                images: [
                  {
                    src: "assets/images/hanging.png",
                    width: 50,
                    height: 50,
                  },
                  {
                    src: "assets/images/hanging.png",
                    width: 50,
                    height: 50,
                  },
                ],
              },
              type: "image",
            },
            size: {
              random: {
                enable: false,
                minimumValue: 1,
              },
              value: 16,
              animation: {
                count: 0,
                enable: false,
                speed: 80,
                decay: 0,
                delay: 0,
                sync: false,
                mode: "auto",
                startValue: "random",
                destroy: "none",
                minimumValue: 0.1,
              },
            },
            stroke: {
              width: 0,
              color: {
                value: "#000000",
                animation: {
                  h: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    delay: 0,
                    decay: 0,
                    sync: true,
                  },
                  s: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    delay: 0,
                    decay: 0,
                    sync: true,
                  },
                  l: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    delay: 0,
                    decay: 0,
                    sync: true,
                  },
                },
              },
            },
            zIndex: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              opacityRate: 1,
              sizeRate: 1,
              velocityRate: 1,
            },
            destroy: {
              bounds: {},
              mode: "none",
              split: {
                count: 1,
                factor: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: 3,
                },
                rate: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: {
                    min: 4,
                    max: 9,
                  },
                },
                sizeOffset: true,
                particles: {},
              },
            },
            roll: {
              darken: {
                enable: false,
                value: 0,
              },
              enable: false,
              enlighten: {
                enable: false,
                value: 0,
              },
              mode: "vertical",
              speed: 25,
            },
            tilt: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              animation: {
                enable: false,
                speed: 0,
                decay: 0,
                sync: false,
              },
              direction: "clockwise",
              enable: false,
            },
            twinkle: {
              lines: {
                enable: false,
                frequency: 0.05,
                opacity: 1,
              },
              particles: {
                enable: false,
                frequency: 0.05,
                opacity: 1,
              },
            },
            wobble: {
              distance: 5,
              enable: false,
              speed: {
                angle: 50,
                move: 10,
              },
            },
            life: {
              count: 0,
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
                sync: false,
              },
              duration: {
                random: {
                  enable: false,
                  minimumValue: 0.0001,
                },
                value: 0,
                sync: false,
              },
            },
            rotate: {
              random: {
                enable: true,
                minimumValue: 0,
              },
              value: 0,
              animation: {
                enable: true,
                speed: 5,
                decay: 0,
                sync: false,
              },
              direction: "random",
              path: false,
            },
            orbit: {
              animation: {
                count: 0,
                enable: false,
                speed: 1,
                decay: 0,
                delay: 0,
                sync: false,
              },
              enable: false,
              opacity: 1,
              rotation: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 45,
              },
              width: 1,
            },
            links: {
              blink: false,
              color: {
                value: "#000",
              },
              consent: false,
              distance: 150,
              enable: false,
              frequency: 1,
              opacity: 0.4,
              shadow: {
                blur: 5,
                color: {
                  value: "#000",
                },
                enable: false,
              },
              triangles: {
                enable: false,
                frequency: 1,
              },
              width: 1,
              warp: false,
            },
            repulse: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              enabled: false,
              distance: 1,
              duration: 1,
              factor: 1,
              speed: 1,
            },
          },
          pauseOnBlur: true,
          pauseOnOutsideViewport: true,
          responsive: [],
          smooth: false,
          style: {},
          themes: [],
          zLayers: 100,
          motion: {
            disable: false,
            reduce: {
              factor: 4,
              value: true,
            },
          },
        }}
      />
      <div name='about' className=' hero_sec '>
        {/* <input id='mosquito1' type='radio' />
        <label htmlFor='mosquito1'>
          <img className='mosquito fly-1' src='assets/images/hanging.png' />
        </label>
        <input id='mosquito2' type='radio' />
        <label htmlFor='mosquito2'>
          <img className='mosquito fly-2' src='assets/images/hanging.png' />
        </label>
        <input id='mosquito3' type='radio' />
        <label htmlFor='mosquito3'>
          <img className='mosquito fly-3' src='assets/images/hanging.png' />
        </label>
        <input id='mosquito4' type='radio' />
        <label htmlFor='mosquito4'>
          <img className='mosquito fly-4' src='assets/images/hanging.png' />
        </label>
        <input id='mosquito5' type='radio' />
        <label htmlFor='mosquito5'>
          <img className='mosquito fly-5' src='assets/images/hanging.png' />
        </label>
        <input id='mosquito6' type='radio' /> */}
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-5 p-0 '>
              <img
                src='assets/images/mousk.png'
                className='w-2/3 relative mt-20 z-10'
                alt=''
              />
              {/* <CountDown /> */}
              <h2 className=' font-sans mb-3'>We Are On Raydium</h2>

              <a
                href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=4bno8H&outputCurrency=4bno8HM1sY8QsrNDtG3UUovRxp2kh7aP6dLAdDe5TGe1&fixed=out'
                target='_blank'
                rel='noopener noreferrer'>
                <button className="cursor-pointer mt-4 m-auto flex animate-bounce justify-center font-sans uppercase text-white font-normal relative text-[22px] w-[15em] py-2 text-center bg-gradient-to-r from-violet-500 from-10% via-sky-500 via-30% to-pink-500 to-90% bg-[length:400%] rounded-[30px] z-10 hover:animate-gradient-xy hover:bg-[length:100%] before:content-[''] before:absolute before:-top-[2px] before:-bottom-[2px] before:-left-[2px] before:-right-[5px] before:bg-gradient-to-r before:from-violet-800 before:from-10% before:via-sky-500 before:via-30% before:to-pink-500 before:bg-[length:400%] before:-z-10 before:rounded-[35px] before:hover:blur-xl before:transition-all before:ease-in-out before:duration-[1s] before:hover:bg-[length:10%] active:bg-violet-700 focus:ring-violet-700">
                  Trade on Raydium
                </button>
              </a>
            </div>
            <div className='col-md-7  '>
              <div className='pt-14 flex items-center w-3/4'>
                <img src='assets/images/coin1.png' className='w-2/6' alt='' />
                <h2 className='lg:text-9xl text-8xl font-sans  '>Mouski</h2>
              </div>
              <div className='lg:w-3/4 px-2'>
                <p className='text-center text-xs font-semibold'>
                  Contract Address:
                </p>
                <h4
                  onClick={() =>
                    navigator.clipboard
                      .writeText(`4bno8HM1sY8QsrNDtG3UUovRxp2kh7aP6dLAdDe5TGe1`)
                      .then(() => {
                        toast.success("Copy to clipboard success");
                      })
                  }
                  className='text-[#000] relative z-10 text-center cursor-pointer bg-[#fff] py-1 lg:text-lg text-xs font-sans rounded-lg border-black border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px]'>
                  4bno8HM1sY8QsrNDtG3UUovRxp2kh7aP6dLAdDe5TGe1
                </h4>
              </div>
              <audio
                id='myAudio'
                autoPlay='true'
                controls='true'
                loop='true'
                className='relative z-10'
                playsInline>
                <source src='GFG.ogg' type='audio/ogg' />
                <source src='music.mp3' type='audio/mpeg' />
                Your browser does not support the audio element.
              </audio>

              <div className='lg:w-3/5 pt-4 lg:ml-20 mx-4 lg:mt-10 flex justify-center relative z-10'>
                <div className='flex justify-between lg:space-x-10 space-x-8 font-bold text-white items-center'>
                  <div className='break-1'>
                    <a
                      href='https://www.pinksale.finance/solana/launchpad/BWhoxyuCdcD6iwrW8GqcYiSUK5CQ8WdvzJuiEKvfY9kA'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <img
                        src='assets/images/pinksale-1.svg'
                        className='img-fluid w-64'
                        alt=''
                      />
                    </a>
                    <div className='arrow'>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div className='break-2'>
                    <a
                      href='https://dexview.com/'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <img
                        src='assets/images/dexview.svg'
                        className='img-fluid  w-64'
                        alt=''
                      />
                    </a>
                    <div className='arrow'>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div className='break-3'>
                    <a
                      href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=4bno8H&outputCurrency=4bno8HM1sY8QsrNDtG3UUovRxp2kh7aP6dLAdDe5TGe1&fixed=out'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <img
                        src='assets/images/logo-with-text.svg'
                        className='img-fluid  w-64'
                        alt=''
                      />
                    </a>
                    <div className='arrow'>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className=' flex items-center mt-2 overlay'>
                <div className='w-1/6 z-10 overlay_inner_left'>
                  <img
                    src='assets/images/push.png'
                    className='lg:w-6/6 w-4/4 mt-2 scale-150'
                    alt=''
                  />
                </div>
                <div className='bg-gra overlay_inner  py-[10px] rounded-xl border-black border-[2px] w-3/4 '></div>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          <div className='bg-[#9945FF]  py-[20px]'></div>
          <div className='bg-[#14F195]  py-[10px]'></div>
          <div className='bg-[#9f64e7]  py-[10px]'></div>
        </div>
      </div>
    </>
  );
};

export default Hero;
