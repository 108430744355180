/** @format */

import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as Links } from "react-router-dom";

import { FaXTwitter, FaTelegram } from "react-icons/fa6";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <nav className=' mx-4 py-4 fixed-top'>
        <div className='container-fluid  px-4 py-2 bg-[#000] rounded-lg border-white border-r-[3px] border-t-[3px] border-l-[3px] border-b-[6px] mx-auto flex justify-between items-center'>
          <div className='hidden md:flex flex-wrap justify-center md:justify-end space-x-6 '>
            <Links
              to='/'
              className='text-[#fff] cursor-pointer hover:text-[#14F195] font-sans  text-lg no-underline capitalize'>
              Home
            </Links>
            <Link
              activeClass='active'
              to='about'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='text-[#fff] cursor-pointer hover:text-[#14F195] font-sans  text-lg no-underline capitalize'>
              About
            </Link>
            <Link
              activeClass='active'
              to='section2'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='text-[#fff] cursor-pointer hover:text-[#14F195] font-sans  text-lg no-underline capitalize'>
              who is Mouski
            </Link>
            <Link
              activeClass='active'
              to='Mouski'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='text-[#fff] cursor-pointer hover:text-[#14F195] font-sans  text-lg no-underline capitalize'>
              MouskiNomics
            </Link>
            <Link
              activeClass='active'
              to='roadmap'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='text-[#fff] cursor-pointer hover:text-[#14F195] font-sans  text-lg no-underline capitalize'>
              Roadmap
            </Link>
            <Links
              to='/raids'
              className='text-[#fff] cursor-pointer hover:text-[#14F195] font-sans  text-lg no-underline capitalize'>
              RAIDS
            </Links>
          </div>
          <div className='lg:flex justify-between items-center hidden'>
            <a
              href='https://t.me/MouskiSol'
              target='_blank'
              rel='noreferrer'
              className='rounded-lg cursor-pointer border-black border-r-[5px] border-t-[3px] border-l-[3px] border-b-[3px] mx-2'>
              <FaTelegram color='#fff' size={18} className='mx-1 my-1' />
            </a>
            <a
              href='https://twitter.com/MouskiSol'
              target='_blank'
              rel='noreferrer'
              className='rounded-lg cursor-pointer border-black border-r-[5px] border-t-[3px] border-l-[3px] border-b-[3px] '>
              <FaXTwitter color='#fff' size={18} className='mx-1 my-1' />
            </a>
            <a href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=4bno8H&outputCurrency=4bno8HM1sY8QsrNDtG3UUovRxp2kh7aP6dLAdDe5TGe1&fixed=out' target='_blank' rel='noopener noreferrer'>
              <button className="cursor-pointer  m-auto flex  justify-center font-sans uppercase text-white font-normal relative text-[22px] w-[6em] mx-3 animate-pulse  text-center bg-gradient-to-r from-violet-500 from-10% via-sky-500 via-30% to-pink-500 to-90% bg-[length:400%] rounded-[30px] z-10 hover:animate-gradient-xy hover:bg-[length:100%] before:content-[''] before:absolute before:-top-[2px] before:-bottom-[2px] before:-left-[2px] before:-right-[5px] before:bg-gradient-to-r before:from-violet-800 before:from-10% before:via-sky-500 before:via-30% before:to-pink-500 before:bg-[length:400%] before:-z-10 before:rounded-[35px] before:hover:blur-xl before:transition-all before:ease-in-out before:duration-[1s] before:hover:bg-[length:10%] active:bg-violet-700 focus:ring-violet-700">
                Buy $Mouski
              </button>
            </a>
          </div>
          <div className='md:hidden '>
            {/* Mobile menu button (Hamburger icon) */}
            <button className='text-[#fff]' onClick={toggleMenu}>
              <svg
                className='h-6 w-6'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path d='M4 6h16M4 12h16m-7 6h7'></path>
              </svg>
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className='md:hidden'>
            <div className='flex flex-col text-center space-y-2 py-3 rounded-md bg-black'>
              <Links
                to='/'
                className='text-[#fff] font-sans font-medium no-underline capitalize'>
                Home
              </Links>
              <Link
                activeClass='active'
                to='about'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className='text-[#fff] font-sans font-medium no-underline capitalize'>
                About
              </Link>
              <Link
                activeClass='active'
                to='section2'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className='text-[#fff] font-sans font-medium no-underline capitalize'>
                who is Mouski
              </Link>
              <Link
                activeClass='active'
                to='Mouski'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className='text-[#fff] font-sans font-medium no-underline capitalize'>
                MouskiNomics
              </Link>
              <Link
                activeClass='active'
                to='roadmap'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className='text-[#fff] font-sans font-medium no-underline capitalize'>
                Roadmap
              </Link>
              <Links
                to='/raids'
                className='text-[#fff] font-sans font-medium no-underline capitalize'>
                RAIDS
              </Links>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
